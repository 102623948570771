const axios = require('axios');

async function loadObjects(language) {
    console.log('[loadObjects] language: ', language);
    const baseUrl = process.env.VUE_APP_BASE_TYPO3_URL;
    if (process.env.VUE_APP_OFFLINE_BUILD === 'true') {
        return loadObjectsFromFileSystem(language);
    }

    if (process.env.VUE_APP_SALES_VARIANT === 'true') {
        return loadObjectsForSalesVariant(language);
    }
    console.log('baseUrl: ', baseUrl);
    const url = `${baseUrl}fileadmin/na15_showroom/showroom_data_${language}.json`;
    const axiosConfig = {};

    // TODO change in production to ===
    if (process.env.NODE_ENV !== 'development') {
        axiosConfig.auth = {
            username: 'nullacht15',
            password: '0815preview'
        };
    }

    const response = await axios.get(url, axiosConfig);
    console.log(response.data);
    return response.data;
}

async function loadObjectsFromFileSystem(language) {
    const fileContext = require.context('@/assets/static/content/', false, /\.json$/);

    const filePath = fileContext.keys().find((key) => key.includes(`showroom_data_${language}.json`));

    const data = filePath ? fileContext(filePath) : null;
    console.log('[loadObjectsFromFileSystem] data: ', data);
    return data;
}

async function loadObjectsForSalesVariant(language) {
    const fileContext = require.context('@/assets/static/content/', false, /\.json$/);

    const filePath = fileContext.keys().find((key) => key.includes(`showroom_sales_data_${language}.json`));

    const data = filePath ? fileContext(filePath) : null;
    console.log('[loadObjectsForSalesVariant] data: ', data);
    return data;
}

async function loadServiceObjectsFromFileSystem(language) {
    const fileContext = require.context('@/assets/static/content/', false, /\.json$/);

    const filePath = fileContext.keys().find((key) => key.includes(`service_data_${language}.json`));
    console.log('[loadServiceObjectsFromFileSystem] fileContext.keys(): ', fileContext.keys());

    return filePath ? fileContext(filePath) : [];
}

export { loadObjects, loadObjectsFromFileSystem, loadServiceObjectsFromFileSystem };

