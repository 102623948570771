<template>
  <div
      class="service-marker"
      :style="[markerStyle, animationStyle]"
      @click="handleClick"
      :class="{
        'fade-in': animate,
        [marker.type]: true,
        'active': isModalOpen,
        ['' + index]: true,
      }"
  >
    <div class="decorator">
      <div class="step-circle">
        <div class="step service">
          <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 135.3 169.9">
            <path class="cls-1" d="M82.7,166.3c11.8-1.2,23.5-3,34.4-7.8,1.3-.6,2.6-1.2,3.9-1.9,7.6-4.2,12.3-9.2,9.8-20.2-4-17.9-17.2-32.8-34.4-36.4-1.8-.4-4.2,0-5.8,1-15.6,8.9-31.1,8.7-46.7.1-1.7-.9-4.2-1.4-6.1-1-19.7,4.6-34.3,22.7-34.9,42.7-.1,4.3,1.7,7.7,5,10,23,15.9,69.8,13.9,74.7,13.4ZM69.8,98.4c-1.1-1.4-1.8-2.3-2.4-3.3-3-5-.7-12.3,4.8-13.3,4.7-.8,9.8-.4,14.5.5,4.1.8,7.8.2,9.8-3.6,1.2-2.4,2.4-4.9,3.2-7.5,6-18.4-3.1-37.4-20.8-43.7-17.9-6.4-36.7,2.4-43.8,20.5-6.4,16.2,2.7,38.8,19,47.3,4.8,2.5,9.8,3.8,15.8,3ZM106.5,70.7c-1.8,3.3,3,8.7-2.1,11.7-3.8,2.2-8.4,3.1-12.7,4.6-.9.3-1.8.5-2.7.5-1,0-2.3.1-2.9-.4-3.6-2.9-7.8-1.7-11.6-1.3-1.5.2-3.5,2.6-3.7,4.2-.2,1.6,1.4,4.6,2.6,4.8,3.6.7,7.4.7,11.1.1,3.2-.5,6.2-2,9.3-3.1,4.7-1.7,9.4-3.6,14.2-5.3,1.5-.5,3.2-.9,4.6-.6,6.2,1.3,11.1-2.1,11.2-8.4.1-5.7.3-11.4-.1-17.1-.1-2.1-1.7-4.1-2.7-6.2-.7-1.6-2-3-2.1-4.6C115.5,18.8,85.6-1.6,57.4,4.2c-21.2,4.4-38.5,21.9-41.4,43.3-.4,3.1-.9,5.7-3.5,7.8-1.1.9-1.8,3-1.8,4.5-.2,6.1-.3,12.2,0,18.3.2,4.9,4.1,8.1,8.9,7.9,5-.2,8.2-3.4,8.3-8.5.1-5.7.2-11.4-.1-17.1-.1-2-1.6-3.9-2.3-5.9-.7-1.7-1.9-3.5-1.7-5,2.3-16.5,11.2-28.2,26.4-34.9,26-11.4,56.7,5.8,60.8,33.9.3,2.4.3,4.3-1.9,6.2-1.5,1.4-2.5,12.2-2.5,16Z"/>
          </svg>
        </div>
      </div>
<!--      <div v-if="isPulse && !isMobile && showPulse" class="pulse" :class="{'pulse-active': showPulse}"></div>-->
    </div>
    <div class="content">
      <div class="label">
        <span>{{ marker.title }}</span>
      </div>
    </div>
  </div>

  <ContentModal
      :isVisible="isModalOpen"
      :marker="marker"
      @close="isModalOpen = false"
      @update:isVisible="isModalOpen = $event"
      :position="modalPosition"
  />
</template>

<script>
import ContentModal from "@/components/ContentModal";

export default {
  components: {
    ContentModal,
  },
  computed: {
    animationStyle() {
      if (!this.animate) return {};

      const delay = this.index * 0.3

      return {
        animationDelay: `${delay}s`
      };
    },
    markerStyle() {
      return {
        top: this.marker.position.top,
        left: this.marker.position.left
      };
    },
    // isPulse() {
    //   return this.isControlCenterMarker();
    // },
  },
  props: {
    animate: {
      type: Array,
      default: () => [],
    },
    marker: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    activeMarkerId: String,
  },
  watch: {
    activeMarkerId(newVal) {
      if (newVal && this.marker.id.toString() === newVal) {
        this.isModalOpen = true;
      } else {
        this.isModalOpen = false;
      }
    }
  },
  mounted() {
    // Öffne das Modal direkt nach dem Mounten, wenn die IDs übereinstimmen
    console.log('activeMarkerId', this.activeMarkerId);
    if (this.activeMarkerId && this.marker.id.toString() === this.activeMarkerId) {
      this.isModalOpen = true;
    }
  },
  data() {
    return {
      isModalOpen: false,
      isPageOpen: false,
      modalPosition: { top: '0px', left: '0px' },
      isMobile: window.innerWidth <= 1024,
      showPulse: false,
    };
  },
  methods: {
    activatePulse() {
      this.showPulse = true;

      setTimeout(() => {
        this.showPulse = false;
      }, 10000);
    },
    isControlCenterMarker() {
      if (!this.marker || !this.marker.title) {
        return false;
      }
      console.log('this.marker.title', this.marker.title.toLowerCase());

      const controlCenterTitle = [
        'control desk',
        'kontrollzentrum',
        'leitstand',
        'centre de contrôle',
        'poste de contrôle'
      ];

      if (controlCenterTitle.includes(this.marker.title.toLowerCase())) {
        setTimeout(() => {
          this.activatePulse();
        }, 3300);
      }
      return controlCenterTitle.some((title) => this.marker.title.toLowerCase().includes(title));
    },
    getModalPosition() {
      const offsetTop = 3.2; // Höhe des Markers in rem
      const offsetLeft = 3.2; // Breite des Markers in rem

      const newTop = `calc(${this.marker.position.top} + ${offsetTop}rem)`;
      const newLeft = `calc(${this.marker.position.left} + ${offsetLeft}rem)`;

      return {
        top: newTop,
        left: newLeft,
      };
    },
    handleClick(event) {
      event.stopPropagation();
      this.modalPosition = this.getModalPosition();
      this.isModalOpen = true;
    },
  },
}
</script>

<style lang="scss">
.service-marker {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  z-index: 150;
  opacity: 0;

  .decorator {
    margin-right: -20px;
    z-index: 125;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    border: 3px solid $primary-blue;
    transition: border-color .6s, background-color .3s;
    flex-shrink: 0;
    background-color: #ffffff;

    .step-circle .step.service {
      font-size: 1.2rem;
      font-weight: 700;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  .svg-icon--plus {
    width: 1.5rem;
    height: 1.5rem;
    color: transparent !important;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 0;
    height: 3.45rem;
  }

  .label {
    margin-left: 20px;
    font-weight: 400;
    padding: 0 16px;
    font-size: 1.1rem;
    height: 100%;
    left: 0;
    max-width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 90%;
    }
  }

  &:hover {
    .content {
      min-width: calc(100% + 32px);
      width: 100%;

      .label {
        z-index: 150;
        max-width: 100%;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .decorator, .content {
    background-color: #ffffff;

    .label {
      color: $primary-blue;
    }
  }

  //&.data-flow {
  //  .decorator, .content {
  //    background-color: #ffffff;
  //
  //    .label {
  //      color: $primary-blue;
  //    }
  //  }
  //}
  //
  //&.goods-flow {
  //  color: $primary-blue;
  //  .decorator, .content {
  //    background-color: #ffffff;
  //
  //    .label {
  //      color: $primary-blue;
  //    }
  //  }
  //}

  &:hover {
    &.goods-flow {
      .decorator {
        background-color: lighten(#fff, 10%);
        transition-duration: .2s;
      }
    }

    &.data-flow {
      .decorator {
        background-color: lighten($primary-blue, 10%);
        transition-duration: .2s;
      }
    }
  }
}

.pulse {
  width: 300px;
  height: 300px;
  background: rgb(24 41 131 / 50%);
  border: 1px solid rgba(128, 159, 255, 0.12);
  border-radius: 50%;
  pointer-events: none;
  position: absolute;
  transform: scale(0.1, 0.1);
  opacity: 0;
  animation: none;
  z-index: -1;
}

.pulse-active {
  animation: ring-1 3s ease-out 3, fadeOut 1s ease-in-out 9s forwards;
}

@keyframes ring-1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: .9;
    filter: blur(5px);
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
    filter: blur(5px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInAnimation {
  0%   { opacity:0; }
  100% { opacity:1; }
}

.fade-in {
  animation: fadeInAnimation .8s ease forwards;
}

@media (max-width: 1024px) {
  .service-marker {
    position: static;
    margin: 0.5rem 0;

    .content {
      display: inline-block;
      width: 100%;
    }

    .label {
      width: 100%;
      max-width: 100%;
      opacity: 1;
      visibility: visible;
      left: auto;
      transform: translateY(0);

      @media (max-width: 1024px) {
        //width: calc(90% - 32px);
      }
    }

    &:hover {
      .content {
        width: 100%;
        .label {
          width: 100%;
          max-width: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
</style>
