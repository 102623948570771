class ShowroomManager {

    constructor() {
        this.floorImages = new Set(['01-floor']);
        this.wallImages = new Set(['09-wall']);
        this.objectImages = new Set(['02-pick-and-place', '03-picking', '04-warehouse-solution', '05-shelving-systems', '06-container-storage', '07-shuttle-container', '08-controlling', '09-wall']);
        this.leftLkwImages = new Set(['lkw1_w']);
        this.rightLkwImages = new Set(['lkw2_w']);
    }

    extractImageName(imagePath) {
        return imagePath.split('/')[1].split('.')[0];
    }

    isOfType(imagePath, typeSet) {
        const imageName = this.extractImageName(imagePath);
        return typeSet.has(imageName);
    }

    buildBuilding(images, callback) {
        setTimeout(() => {
            this.buildFloors(images, callback, () => {
                this.buildObjectImages(images, callback, () => {
                    console.log('Building walls: content-images before:', images);
                });
            });
        }, 1000);
    }

    buildFloors(images, callback, onComplete) {
        let builtCount = 0;
        images.forEach((image, index) => {
            if (this.isOfType(image.src, this.floorImages)) {
                this.setVisibilityWithDelay(image, index, callback, 0, () => {
                    builtCount++;
                    if (builtCount === images.filter(image => this.isOfType(image.src, this.floorImages)).length) onComplete();
                });
            }
        });
        if (builtCount === 0) onComplete();
    }

    buildWalls(images, callback, onComplete) {
        let processedCount = 0;
        let wallImagesCount = this.wallImages.size;
        if (wallImagesCount === 0) {
            onComplete();
        } else {
            images.forEach((image, index) => {
                if (this.isOfType(image.src, this.wallImages)) {
                    const delay = 500 * index;
                    this.setVisibilityWithDelay(image, index, callback, delay, () => {
                        processedCount++;
                        if (processedCount === wallImagesCount) {
                            onComplete();
                        }
                    });
                }
            });
        }
    }

    buildObjectImages(images, callback, onComplete) {
        let zIndex = 1;
        const totalImages = images.length;
        let builtCount = 0;
        images.forEach((image, index) => {
            if (this.isOfType(image.src, this.objectImages)) {
                const delay = 500 * (index + 1);
                this.setVisibilityWithDelay(image, index, callback, delay, () => {
                    builtCount++;
                    zIndex++;
                    image.style = {
                        zIndex: totalImages - zIndex // Umgekehrter z-index
                    };
                    // if (builtCount === (objectImageCount - 2)) {
                    //     this.buildWalls(content-images, callback, () => {
                    //         this.buildLkws(content-images, callback);
                    //         onComplete();
                    //     });
                    // }
                });

                if (this.extractImageName(image.src) === '06-container-storage') {
                    console.log('build wall')
                    this.buildWalls(images, callback, () => {
                        this.buildLkws(images, callback, () => {});
                    });
                }
            }
        });
        if (builtCount === 0) onComplete();
    }

    setVisibilityWithDelay(image, index, callback, delay, onComplete = () => {}) {
        setTimeout(() => {
            callback(index, true);
            image.class = image.class.replace('hidden', '').trim();
            // console.log('img.src', image.src);
            if (this.isOfType(image.src, this.leftLkwImages) && !image.animated) {
                this.animateLeftLkws({ image, index });
                image.animated = true;
            } else if (this.isOfType(image.src, this.rightLkwImages) && !image.animated) {
                this.animateRightLkws({ image, index });
                image.animated = true;
            }
            onComplete();
        }, delay);
    }

    buildLkws(images, callback) {

        images.forEach((image, index) => {
            if (this.isOfType(image.src, this.leftLkwImages) || this.isOfType(image.src, this.rightLkwImages)) {
                if (!image.class.includes('hidden')) {
                    image.class += ' hidden';
                }
                const delay = 500;
                this.setVisibilityWithDelay(image, index, callback, delay);
            }
        });
    }

    animateLeftLkws(params) {
        console.log('Animating Lkws to the left with params:', params);
        params.image.class = params.image.class.replace('hidden', '').trim() + ' animate-left';
    }

    animateRightLkws(params) {
        console.log('Animating Lkws to the right with params:', params);
        params.image.class = params.image.class.replace('hidden', '').trim() + ' animate-right';
    }
}

export default ShowroomManager;