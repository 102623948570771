<template>
  <div class="loading-screen" v-if="isVisible">
    <div class="loading-spinner-container">
      <div class="loading-spinner"></div> <!-- Spinner-Element -->
      <span class="loading-percentage">{{ Math.round(progress) }}%</span> <!-- Prozentanzeige -->
    </div>
    <div class="loading-title">
      <h3>{{ $t('loading.title') }}</h3>
    </div>
    <div class="progress-bar" :style="{ width: progress + '%' }"></div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isVisible: true
    };
  },
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },
  methods: {
    async loadResources() {
      if (process.env.VUE_APP_INLINE_BUILD === 'true') {
        console.log('[LoadingScreen] Skipping resource loading in inline mode');
        this.$emit('loading-complete');
        this.isVisible = false;
        return;
      }

      const allImages = require.context('@/assets/static', false, /\.(png|jpe?g|gif|svg)$/);
      const resourcesToLoad = allImages.keys().map((key) => allImages(key));
      const totalResources = resourcesToLoad.length;
      console.log('[LoadingScreen] resourcesToLoad: ', totalResources);
      let loadedResources = 0;

      try {
        await Promise.all(
            resourcesToLoad.map((url) => {
              return new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = () => {
                  loadedResources++;
                  const progress = (loadedResources / totalResources) * 100;
                  // console.log('[LoadingScreen] progress: ', progress);
                  this.$emit('update-progress', progress);
                  resolve();
                };

                image.onerror = () => {
                  reject();
                };

                image.src = url;
              });
            })
        );

        this.$emit('loading-complete');
        this.isVisible = false;
      } catch (error) {
        console.error('Fehler beim Laden der Ressourcen:', error);
        this.isVisible = false;
      }
    }
  },
  mounted() {
    this.loadResources();
  }
}
</script>

<style lang="scss">

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loading-spinner {
  position: relative;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid $primary-blue;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  opacity: 1;
  transition: opacity 0.5s ease, visibility 0.5s;
}

.loading-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: $primary-blue;
  z-index: 2;
}

h3 {
  font-size: 1.3rem;
  color: $primary-blue;
  margin-top: 1rem;
}
</style>
