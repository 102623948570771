<template>
  <svg class="control-center-lines" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 101;">
    <defs>
      <filter id="nebula-cc" x="0" y="0" width="200%" height="200%">
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur"/>
        <feTurbulence type="fractalNoise" baseFrequency="0.02" numOctaves="5" result="turbulence">
          <animate attributeName="baseFrequency" dur="30s" values=".01;.02" repeatCount="indefinite"/>
        </feTurbulence>
        <feDisplacementMap in="blur" in2="turbulence" scale="10" xChannelSelector="R" yChannelSelector="G"/>
        <feComposite in="SourceGraphic" in2="displacementMap" operator="in" result="composite"/>
      </filter>
    </defs>

    <line
        v-for="(line, index) in controlCenterLines"
        :key="index"
        :class="{'animate-line-cc': animateControlCenterLines[index]}"
        :x1="line.start.x"
        :y1="line.start.y"
        :x2="line.end.x"
        :y2="line.end.y"
        :style="getLineAnimationStyle(index, calculateLineLength(line))"
        stroke="#ffffff"
    stroke-width="6"
    filter="url(#nebula-cc)"
    />
  </svg>
</template>

<script>
export default {
  name: 'ControlCenterLines',
  props: ['controlCenterLines', 'animateControlCenterLines'],
  methods: {
    getLineAnimationStyle(index, length) {
      // const delay = index * 0.4;
      return {
        strokeDasharray: `${length}`,
        strokeDashoffset: `${length}`,
        animation: `drawControlCenterLine${index} 1.8s linear forwards`,
      };
    },
    calculateLineLength(line) {
      const dx = line.end.x - line.start.x;
      const dy = line.end.y - line.start.y;
      return Math.sqrt(dx * dx + dy * dy);
    },
  }
};
</script>
