<template>
  <div class="main">
    <LoadingScreen
        v-if="!loadingComplete"
        :progress="progress"
        @update-progress="updateProgress"
        @loading-complete="loadingComplete = true"
    />
    <div :style="backgroundStyle" class="background-image-container"></div>
    <header v-if="showHeader" class="app-header fade-in" v-bind:class="{ 'visible': headerVisible }">
      <div class="flow-holder">
        <!--        check if not inline build-->
        <div v-if="!isInlineFrame && logoVisible" class="app-logo">
          <a href="https://www.stoecklin.com/en-ch" target="_blank" class="link" title="Stöcklin logo">
            <img src="@/assets/stoecklin_logo.png" alt="Logo"/>
          </a>
        </div>
        <FlowTypeWrapper
            :current-flow-type="currentFlowType"
            @update-flow-type="updateFlowType"
        />
      </div>
      <div class="action-menu">
        <div class="language-wrapper" v-if="languageWrapperVisible">
          <label for="languageSelect" class="visually-hidden">Language</label>
          <select v-model="currentLanguage" name="languageSelect" id="languageSelect">
            <option value="en-EN">EN</option>
            <option value="de-DE">DE</option>
            <option value="fr-FR">FR</option>
            <option v-if="showSpanish" value="es-ES">ES</option>
            <option v-if="showItalian" value="it-IT">IT</option>
            <option v-if="showDutch" value="nl-NL">NL</option>
            <option v-if="showSlovene" value="sl-SI">SL</option>
          </select>
        </div>
      </div>
    </header>
    <div v-show="!isInlineFrame && !isMobile" v-for="(image, index) in objectImages" :key="index" class="object-wrapper"
         :style="{ opacity: image.visible ? 1 : 0, zIndex: image.style?.zIndex }">
      <img
          :src="image.src"
          v-bind:class="['object-image', image.class]"
          :alt="image.src"
      >
    </div>
    <div v-if="!isMobile">
      <MapMarker
          v-for="(marker, index) in filteredMarkers"
          :key="marker.id"
          :marker="marker"
          :animate="animateMarkers"
          :index="index"
          :activeMarkerId="activeMarkerId"
      />
      <ServiceMarker
          v-for="(marker, index) in serviceMarkers"
          :key="marker.id"
          :marker="marker"
          :animate="animateMarkers"
          :index="index"
      />
      <DataFlowLines
          v-if="currentFlowType === 'data-flow'"
          :dataFlowLines="dataFlowLines"
          :animateLines="animateLines"
      />
      <ControlCenterLines
          v-if="currentFlowType === 'data-flow'"
          :controlCenterLines="controlCenterLines"
          :animateControlCenterLines="animateControlcenterLines"
      />
    </div>
    <div v-else class="mobile-markers">
      <ServiceMarker
          v-for="(marker, index) in serviceMarkers"
          :key="marker.id"
          :marker="marker"
          :animate="animateMarkers"
          :index="index"
      />
      <MapMarker
          v-for="(marker, index) in filteredMarkers"
          :key="marker.id"
          :marker="marker"
          :animate="animateMarkers"
          :index="index"
      />
    </div>
  </div>
  <Footer v-if="!isInlineFrame && logoVisible"/>
</template>

<script>
import MapMarker from "@/components/MapMarker";
import LoadingScreen from "@/components/screens/LoadingScreen";
import FlowTypeWrapper from "@/components/widgets/FlowTypeWrapper";
import { loadObjects, loadServiceObjectsFromFileSystem } from "@/api/loadObjects";
import ShowroomManager from "@/utils/ShowroomManager";
import Footer from "@/components/Footer.vue";
import DataFlowLines from "@/components/DataFlowLines.vue";
import ControlCenterLines from "@/components/ControlCenterLines.vue";
import ServiceMarker from "@/components/ServiceMarker.vue";

export default {
  components: {
    ServiceMarker,
    DataFlowLines,
    ControlCenterLines,
    LoadingScreen,
    MapMarker,
    FlowTypeWrapper,
    Footer
  },
  mounted() {
    if (this.isInlineFrame || !this.logoVisible) {
      const app = document.getElementById('app');
      if (app) {
        app.classList.add('inline');
      }
    }

    window.addEventListener('openModal', this.handleOpenModalEvent);
    window.addEventListener('switchFlowType', this.handleSwitchFlowTypeEvent);

    if (this.activeMarkerId && this.marker.id.toString() === this.activeMarkerId) {
      this.isModalOpen = true;
    }

    setTimeout(() => {
      this.showHeader = true;
      // Header einfaden
      setTimeout(() => {
        this.headerVisible = true;
      }, 10000);

      setTimeout(() => {
        this.showLoadingScreen = true;
      }, 500); // Einblenden des LoadingScreens nach dem Header
    }, 300); // Einblenden des Headers

    const storedLanguage = localStorage.getItem('userLanguage');
    this.currentLanguage = storedLanguage || this.currentLanguage;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('lang')) {
      console.log('[App] mounted -> urlParams.get(lang): ', urlParams.get('lang'));
      this.currentLanguage = urlParams.get('lang');
    }
    this.$i18n.locale = this.currentLanguage.split('-')[0];
    this.checkMobile();
    this.updateDataFlowLines();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
    window.removeEventListener('openModal', this.handleOpenModalEvent);
    window.removeEventListener('switchFlowType', this.handleSwitchFlowTypeEvent);
  },
  computed: {
    websiteUrl() {
      // return '';
      return process.env.VUE_APP_BASE_TYPO3_URL;
    },
    logoVisible() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('logo') !== 'false';
    },
    languageWrapperVisible() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('showLanguage') !== 'false';
    },
    filteredMarkers() {
      let filtered = this.currentFlowType === 'all'
          ? this.markers
          : this.markers.filter(marker => marker.type === this.currentFlowType);

      return filtered
          .slice()
          .sort((a, b) => {
            if (a.type === b.type) {
              return a.sorting - b.sorting;
            }
            return a.type.localeCompare(b.type);
          })
          .map((marker, index) => {
            return {
              ...marker,
              step: index + 1
            };
          });
    },
    isInlineFrame() {
      console.log('process.env.VUE_APP_INLINE_BUILD: ', process.env.VUE_APP_INLINE_BUILD);
      return process.env.VUE_APP_INLINE_BUILD === 'true';
    },
    isSalesShowroom() {
      return process.env.VUE_APP_SALES_VARIANT === 'true';
    },
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        opacity: this.backgroundOpacity,
        transition: 'opacity 4s ease',
      };
    },
    controlCenterMarker() {
      const controlCenterTitles = [
        'control desk',
        'kontrollzentrum',
        'leitstand',
        'centre de contrôle',
        'poste de contrôle',
        'sportello di controllo',
        'mesa de control',
        'controlecentrum',
        'nadzorni zaslon'
      ];
      return this.markers.find(marker => controlCenterTitles.includes(marker.title.toLowerCase()));
    },
  },
  data() {
    return {
      loadingComplete: false,
      showHeader: false,
      headerVisible: false,
      showLoadingScreen: false,
      backgroundOpacity: 0,
      showroomManager: null,
      animationsComplete: false,
      showLoadingForMinimumTime: false,
      progress: 0,
      markers: [],
      serviceMarkers: [],
      currentLanguage: localStorage.getItem('userLanguage') || 'de-DE',
      currentFlowType: 'goods-flow',
      animateMarkers: [],
      isMobile: window.innerWidth <= 1024,
      backgroundImage: '',
      objectImages: [],
      settings: null,
      resizeTimeout: null,
      dataFlowLines: [],
      animateLines: [],
      controlCenterLines: [],
      animateControlcenterLines: [],
      dataFlowTimeout: null,
      controlCenterTimeout: null,
      activeMarkerId: null,
      newLanguageLaunchDate: new Date('2024-11-15T12:00:00'),
      showSpanish: true,
      showItalian: true,
      showDutch: true,
      showSlovene: false,
    }
  },
  created() {
    this.showroomManager = new ShowroomManager();
    const urlParams = new URLSearchParams(window.location.search);
    this.activeMarkerId = urlParams.get('markerId');
    this.checkLanguageAvailability();
  },
  methods: {
    checkMobile() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.isMobile = window.innerWidth <= 1024;
        if (!this.isMobile) {
          // this.calculateMarkerPositions();
          // this.updateDataFlowLines();
        }
      }, 150);
    },
    updateDataFlowLines() {
      if (this.currentFlowType !== 'data-flow') {
        this.dataFlowLines = [];
        this.animateLines = [];
        this.controlCenterLines = [];
        this.animateControlcenterLines = [];
        return;
      }
      if (this.isMobile) {
        return;
      }

      clearTimeout(this.dataFlowTimeout);

      const markerRadius = 10;
      const lines = [];
      const dataFlowMarkers = this.filteredMarkers.filter(marker => marker.type === 'data-flow');
      const bgContainer = document.querySelector('.background-image-container');
      if (!bgContainer) {
        return;
      }
      const bgContainerWidth = bgContainer.offsetWidth;
      const bgContainerHeight = bgContainer.offsetHeight;

      for (let i = 0; i < dataFlowMarkers.length - 1; i++) {
        const startMarker = dataFlowMarkers[i];
        const endMarker = dataFlowMarkers[i + 1];

        // Berechne den Winkel zwischen den Markern
        const deltaY = this.percentToNumber(endMarker.position.top) * bgContainerHeight - this.percentToNumber(startMarker.position.top) * bgContainerHeight;
        const deltaX = this.percentToNumber(endMarker.position.left) * bgContainerWidth - this.percentToNumber(startMarker.position.left) * bgContainerWidth;
        const angle = Math.atan2(deltaY, deltaX);

        const startX = this.percentToNumber(startMarker.position.left) * bgContainerWidth + Math.cos(angle) * markerRadius + 20;
        const startY = this.percentToNumber(startMarker.position.top) * bgContainerHeight + Math.sin(angle) * markerRadius + 20;
        const endX = this.percentToNumber(endMarker.position.left) * bgContainerWidth - Math.cos(angle) * markerRadius + 20;
        const endY = this.percentToNumber(endMarker.position.top) * bgContainerHeight - Math.sin(angle) * markerRadius + 20;

        lines.push({
          start: {x: startX, y: startY},
          end: {x: endX, y: endY},
        });
      }

      this.dataFlowTimeout = setTimeout(() => {
        this.dataFlowLines = lines;
        this.animateLines = this.dataFlowLines.map(() => true);
        this.insertLineAnimations();

        // Verzögern der Control Center Lines Animation
        this.controlCenterTimeout = setTimeout(() => {
          this.calculateAndAnimateControlCenterLines();
        }, 2100);
      }, 2200);
    },
    calculateAndAnimateControlCenterLines() {
      if (this.isMobile || !this.controlCenterMarker) {
        return;
      }

      clearTimeout(this.controlCenterTimeout);

      const markerRadius = 10;
      const lines = [];
      const bgContainer = document.querySelector('.background-image-container');
      if (!bgContainer) {
        return;
      }
      const bgContainerWidth = bgContainer.offsetWidth;
      const bgContainerHeight = bgContainer.offsetHeight;

      const dataFlowMarkers = this.filteredMarkers;

      console.log('[App] calculateAndAnimateControlCenterLines -> dataFlowMarkers: ', dataFlowMarkers);

      dataFlowMarkers.forEach(targetMarker => {
        if (targetMarker === this.controlCenterMarker) {
          return;
        }

        const deltaY = this.percentToNumber(targetMarker.position.top) * bgContainerHeight - this.percentToNumber(this.controlCenterMarker.position.top) * bgContainerHeight;
        const deltaX = this.percentToNumber(targetMarker.position.left) * bgContainerWidth - this.percentToNumber(this.controlCenterMarker.position.left) * bgContainerWidth;
        const angle = Math.atan2(deltaY, deltaX);

        const startX = this.percentToNumber(this.controlCenterMarker.position.left) * bgContainerWidth + Math.cos(angle) * markerRadius + 20;
        const startY = this.percentToNumber(this.controlCenterMarker.position.top) * bgContainerHeight + Math.sin(angle) * markerRadius + 20;
        const endX = this.percentToNumber(targetMarker.position.left) * bgContainerWidth - Math.cos(angle) * markerRadius + 20;
        const endY = this.percentToNumber(targetMarker.position.top) * bgContainerHeight - Math.sin(angle) * markerRadius + 20;

        lines.push({
          start: {x: startX, y: startY},
          end: {x: endX, y: endY},
        });
      });

      console.log('[App] calculateAndAnimateControlCenterLines -> lines: ', lines);
      this.controlCenterLines = lines;
      this.animateControlcenterLines = this.controlCenterLines.map(() => true);
      this.insertControlCenterLineAnimations();
    },
    percentToNumber(percentString) {
      const match = percentString.match(/^(\d+(?:\.\d+)?)/);
      return match ? parseFloat(match[1]) / 100 : NaN;
    },
    insertLineAnimations() {
      const styleElement = document.createElement('style');
      document.head.appendChild(styleElement);
      const styleSheet = styleElement.sheet;

      this.dataFlowLines.forEach((line, index) => {
        const length = this.calculateLineLength(line);

        const totalLength = length;

        const keyframes = `
        @keyframes drawLine${index} {
          0% {
            stroke-dasharray: ${length} ${totalLength};
            stroke-dashoffset: ${length};
            opacity: 0;
          }
          1% {
            opacity: 1;
          }
          100% {
            stroke-dasharray: ${length} ${totalLength};
            stroke-dashoffset: -${totalLength};
            opacity: 0;
          }
        }
      `;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
      });
    },
    insertControlCenterLineAnimations() {
      if (!this.styleElement) {
        this.styleElement = document.createElement('style');
        document.head.appendChild(this.styleElement);
      }
      const styleSheet = this.styleElement.sheet;

      // Löschen vorhandener CSS-Regeln im styleSheet, um Überschreibungen zu vermeiden
      while (styleSheet.cssRules.length) {
        styleSheet.deleteRule(0);
      }
      this.controlCenterLines.forEach((line, index) => {
        const length = this.calculateLineLength(line);
        const totalLength = length;

        const keyframes = `
        @keyframes drawControlCenterLine${index} {
          0% {
            stroke-dasharray: ${length} ${totalLength};
            stroke-dashoffset: ${length};
            opacity: 0;
          }
          1% {
            opacity: 1;
          }
          100% {
            stroke-dasharray: ${length} ${totalLength};
            stroke-dashoffset: -${totalLength};
            opacity: 0;
          }
        }
      `;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
      });
      console.log('[App] insertControlCenterLineAnimations -> styleSheet: ', styleSheet);
    },
    calculateLineLength(line) {
      const dx = (line.end.x + 20) - line.start.x;
      const dy = (line.end.y + 20) - line.start.y;
      return Math.sqrt(dx * dx + dy * dy);
    },
    async loadData() {
      try {
        let data;
        // if (!this.languageWrapperVisible) {
        //   const urlParams = new URLSearchParams(window.location.search);
        //   this.currentLanguage = urlParams.get('lang') || 'en-EN';
        //   console.log('[App] loadData -> urlParams.get(lang): ', urlParams.get('lang'));
        // }
        data = await loadObjects(this.currentLanguage);

        console.log('[App] loadData data: ', data);
        if (data.data.length > 0) {
          this.markers = data.data.map(marker => {
            console.log('[App] load marker: ', marker);
            return {
              id: marker.uid,
              title: marker.shortTitle,
              contentTitle: marker.contentTitle,
              position: {
                top: `${marker.positionTop}%`,
                left: `${marker.positionLeft}%`
              },
              content: marker.content,
              type: marker.type,
              link: marker.link,
              sorting: marker.sorting,
              images: marker.images ? marker.images : [],
            }
          });
        }

        this.backgroundImage = require('@/assets/images/all_w.png');

        if (this.isSalesShowroom) {
          this.loadServiceData();
        }

        if (data.meta) {
          console.log('[App] loadData data.meta: ', data.meta);
          // if (data.meta.settings && data.meta.settings.showroom && data.meta.settings.showroom.show && data.meta.settings.showroom.show.backgroundImage) {
          //   console.log('[App] loadData data.meta.settings.showroom.show.backgroundImage: ', data.meta.settings.showroom.show.backgroundImage);
          //   this.backgroundImage = process.env.VUE_APP_BASE_TYPO3_URL + data.meta.settings.showroom.show.backgroundImage;
          // } else {
          //   this.backgroundImage = require('@/assets/content-images/all_w.png');
          // }
          this.settings = data.meta;
        }
      } catch (error) {
        console.error("[App] loadData error fetching data:", error);
      }
    },
    loadServiceData() {
      loadServiceObjectsFromFileSystem(this.currentLanguage).then(serviceObjects => {
        this.serviceMarkers = [];
        setTimeout(() => {
          console.log('[App] loadData -> loadServiceObjectsFromFileSystem serviceObjects: ', serviceObjects.data);
          this.serviceMarkers = serviceObjects.data.map(serviceMarker => {
            console.log('[App] loadData -> loadServiceObjectsFromFileSystem marker: ', serviceMarker);
            return {
              id: serviceMarker.uid,
              title: serviceMarker.shortTitle,
              contentTitle: serviceMarker.contentTitle,
              position: {
                top: `${serviceMarker.positionTop}%`,
                left: `${serviceMarker.positionLeft}%`
              },
              content: serviceMarker.content,
              type: serviceMarker.type,
              link: serviceMarker.link,
              sorting: serviceMarker.sorting,
              images: serviceMarker.images ? serviceMarker.images : [],
            }
          });
        }, 2800);
      });
    },
    updateProgress(progress) {
      this.progress = progress;
    },
    showDataFlow() {
      this.currentFlowType = 'data-flow';

    },
    showGoodsFlow() {
      this.currentFlowType = 'goods-flow';

    },
    updateFlowType(newFlowType) {
      if (newFlowType === null) {
        console.log('[App] updateFlowType -> currentFlowType: ', this.currentFlowType);
        newFlowType = this.currentFlowType === 'data-flow' ? 'goods-flow' : 'data-flow';
      }
      this.currentFlowType = newFlowType;
      this.filteredMarkers.forEach((_, index) => {
        console.log('[App] updateFlowType -> filtered name: ', this.filteredMarkers[index].title);
        if (this.currentFlowType === 'data-flow') {
          setTimeout(() => {
            this.animateMarkers[index] = true;
            this.animateLines[index] = true;
            this.animateControlcenterLines[index] = true;

            setTimeout(() => {
              this.animateMarkers[index] = false;
              this.animateLines[index] = false;
              this.animateControlcenterLines[index] = false;
            }, 500); // Dauer der Animation
          }, index * 50); // Verzögerung der Animation
        }
        else {
          this.animateMarkers[index] = false;
          this.animateLines[index] = false;
          this.animateControlcenterLines[index] = false;

          clearTimeout(this.dataFlowTimeout);
          clearTimeout(this.controlCenterTimeout);
        }
      });
      // this.serviceMarkers = [];
      // this.loadServiceData();
    },
    handleOpenModalEvent(event) {
      let { markerIndex } = event.detail;
      if (markerIndex) {
        markerIndex = parseInt(markerIndex, 10) - 1;
        let markerToActivate = this.filteredMarkers[markerIndex];

        if (markerToActivate) {
          console.log('[App] handleOpenModalEvent -> activeMarkerId: ', this.activeMarkerId);
          this.activeMarkerId = markerToActivate.id.toString();
        }
      } else {
        this.activeMarkerId = null;
      }
    },
    handleSwitchFlowTypeEvent() {
      this.updateFlowType(null);
    },
    loadImageObjects() {
      const imagesContext = require.context('@/assets/static', false, /\.png$/);
      const imageUrls = imagesContext.keys().map(imagesContext);

      console.log('[App] loadImageObjects -> imageUrls: ', imageUrls);

      imageUrls.forEach((src) => {
        this.objectImages.push({
          src,
          visible: false,
          class: ''
        });
      });
      this.animateObjectImages();
    },
    animateObjectImages() {
      this.showroomManager.buildBuilding(this.objectImages, (index, visible) => {
        this.objectImages[index].visible = visible;

        if (this.showroomManager.isOfType(this.objectImages[index].src, this.showroomManager.floorImages) ||
            this.showroomManager.isOfType(this.objectImages[index].src, this.showroomManager.wallImages)) {
          this.objectImages[index].class = 'floor-image';
        }
      });
    },
    fadeInBackground() {
      this.backgroundOpacity = 1;
      setTimeout(() => {
        this.removeImageObjects();
      }, 6000);
    },
    removeImageObjects() {
      this.objectImages = [];
    },
    checkLanguageAvailability() {
      const now = new Date();
      if (now >= this.newLanguageLaunchDate) {
        this.showSlovene = true;
      }
    },
  },
  watch: {
    filteredMarkers: {
      deep: true,
      handler() {
        this.updateDataFlowLines();
      }
    },
    activeMarkerId: {
      handler(newVal) {
        if (newVal) {
          this.activeMarkerId = newVal;
        }
      }
    },
    currentLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData();
        console.log('[App] watch currentLanguage changed: ', newVal);
        let languageCode = newVal.split('-')[0];
        this.$i18n.locale = languageCode;
        localStorage.setItem('userLanguage', newVal);
      }
    },
    loadingComplete(newVal) {
      console.log('[App] watch loadingComplete: ', newVal);
      if (newVal && !this.isInlineFrame) {
        this.loadImageObjects();
      }
    },
    objectImages: {
      deep: true,
      handler(newVal) {
        if (this.isInlineFrame && !this.animationsComplete || this.isMobile && !this.animationsComplete) {
          this.loadData();
          this.animationsComplete = true;
          this.fadeInBackground();
          return;
        }

        const excludedImages = ['lkw1_w', 'lkw2_w'];
        const allObjectsAnimated = newVal.every(obj => {
          // console.log('[App] watch obj: ', obj);
          const isExcluded = excludedImages.some(excluded => obj.src.includes(excluded));
          if (isExcluded) {
            return true;
          }
          return obj.visible;
        });

        if (allObjectsAnimated && !this.animationsComplete) {
          this.loadData();
          this.animationsComplete = true;
          setTimeout(() => {
                this.fadeInBackground();
              }, 3000
          );
        }
      },
    }
  },
}
</script>

<style lang="scss">
.app-header {
  position: relative;
  top: 10px;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;

  .flow-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .app-logo {
      margin: 0 20px;

      a {
        text-decoration: none;
        width: 100%;
        height: auto;

        &:hover {
          text-decoration: none;
        }
      }

      img {
        width: 100%;
        max-width: 160px;
        height: auto;
        background-size: contain;

        @media (max-width: 768px) {
          max-width: 120px;
        }
      }
    }
  }

  .action-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 10px;

    .language-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: row;
      column-gap: 5px;

      select {
        width: 100%;
        padding: 1rem 2rem;
        border-radius: 0;
        border: 1px solid #fff;
        appearance: none;
        background-color: #fff;
        font-size: 1.25rem;
        line-height: 1.5;
        cursor: pointer;
        color: $primary-blue;
        transition: all .15s ease-in-out;

        &:focus, &:hover {
          outline: none;
          border: 1px solid $primary-blue;
        }

        option {
          padding: 0.5rem;
        }

        &:active option {
          background-color: $primary-blue;
          color: #fff;
        }
      }
    }
  }
}

.bg-image {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left top;
  border-style: none;
}

@media (min-width: 1020px) {
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
  }
}

@media (min-width: 767px) {
  .action-menu {
    position: relative;
    top: 20px;
    align-self: center;
    padding-right: 30px;
  }

  .bg-image {
    object-position: 25% center;
  }
}

.mobile-markers {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
  width: 100%;
  margin-left: 20px;
  max-height: 50%;
  overflow: scroll;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-bottom: 30px;
  }
}
</style>
