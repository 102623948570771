/*
* Objektsteuerung für Trehow
* */

window.openModal = function (markerIndex = null) {
    const event = new CustomEvent(
      'openModal',
      {
          detail: {
              markerIndex
          }
      });
    window.dispatchEvent(event);
};

window.switchFlowType = function () {
    const event = new CustomEvent('switchFlowType');
    window.dispatchEvent(event);
};
