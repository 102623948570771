<template>
  <div class="flow-wrapper">
    <button
        @click="setFlowType('goods-flow')"
        :class="{ active: currentFlowType === 'goods-flow', 'goods-flow': true }"
        class="btn flow-type"
    >
      {{ $t('flow.showGoodsFlow') }}
    </button>
    <button
        @click="setFlowType('data-flow')"
        :class="{ active: currentFlowType === 'data-flow', 'data-flow': true }"
        class="btn flow-type"
    >
      {{ $t('flow.showDataFlow') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentFlowType: String
  },
  methods: {
    setFlowType(flowType) {
      if (this.currentFlowType !== flowType) {
        this.$emit('update-flow-type', flowType);
      }
    },
  }
};
</script>

<style lang="scss">

.flow-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 0;
  padding: 20px;

  @media (max-width: 767px) {
    .flow-wrapper {
      margin-bottom: 15px;
    }
  }
}

button.flow-type {
  font-size: 1.25rem;
  padding: 1rem 2rem;
  background-color: white;
  color: $primary-blue;
  border-color: white;
  border-radius: 0;

  &:hover {
    background-color: white;
    border-color: $primary-blue;
  }

  &.active {
    color: white;
    background-color: $primary-blue !important;
    border-color: $primary-blue;

    &:hover {
      background-color: white;
      cursor: default;
    }

    &.goods-flow {
      background-color: $primary-yellow !important;
      border-color: $primary-yellow;
      color: $primary-blue;
    }
  }
}
</style>
