<template>
  <div
      class="map-marker"
      :style="[markerStyle, animationStyle]"
      @click="handleClick"
      :class="{
        'fade-in': animate,
        [marker.type]: true,
        'active': isModalOpen,
        ['' + index]: true,
      }"
  >
    <div class="decorator">
      <div class="step-circle">
        <div class="step">{{ marker.step }}</div>
      </div>
<!--      <div v-if="isPulse && !isMobile && showPulse" class="pulse" :class="{'pulse-active': showPulse}"></div>-->
    </div>
    <div class="content">
      <div class="label"><span>
        {{ marker.title }}
      </span></div>
    </div>
  </div>

  <ContentModal
      :isVisible="isModalOpen"
      :marker="marker"
      @close="isModalOpen = false"
      @update:isVisible="isModalOpen = $event"
      :position="modalPosition"
  />
</template>

<script>
import ContentModal from "@/components/ContentModal";

export default {
  components: {
    ContentModal
  },
  computed: {
    animationStyle() {
      if (!this.animate) return {};

      const delay = this.index * 0.3

      return {
        animationDelay: `${delay}s`
      };
    },
    markerStyle() {
      return {
        top: this.marker.position.top,
        left: this.marker.position.left
      };
    },
    // isPulse() {
    //   return this.isControlCenterMarker();
    // },
  },
  props: {
    animate: {
      type: Array,
      default: () => [],
    },
    marker: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    activeMarkerId: String,
  },
  watch: {
    activeMarkerId(newVal) {
      if (newVal && this.marker.id.toString() === newVal) {
        this.isModalOpen = true;
      } else {
        this.isModalOpen = false;
      }
    }
  },
  mounted() {
    // Öffne das Modal direkt nach dem Mounten, wenn die IDs übereinstimmen
    console.log('activeMarkerId', this.activeMarkerId);
    if (this.activeMarkerId && this.marker.id.toString() === this.activeMarkerId) {
      this.isModalOpen = true;
    }
  },
  data() {
    return {
      isModalOpen: false,
      isPageOpen: false,
      modalPosition: { top: '0px', left: '0px' },
      isMobile: window.innerWidth <= 1024,
      showPulse: false,
    };
  },
  methods: {
    activatePulse() {
      this.showPulse = true;

      setTimeout(() => {
        this.showPulse = false;
      }, 10000);
    },
    isControlCenterMarker() {
      if (!this.marker || !this.marker.title) {
        return false;
      }
      console.log('this.marker.title', this.marker.title.toLowerCase());

      const controlCenterTitle = [
        'control desk',
        'kontrollzentrum',
        'leitstand',
        'centre de contrôle',
        'poste de contrôle',
        'nadzorni zaslon'
      ];

      if (controlCenterTitle.includes(this.marker.title.toLowerCase())) {
        setTimeout(() => {
          this.activatePulse();
        }, 3300);
      }
      return controlCenterTitle.some((title) => this.marker.title.toLowerCase().includes(title));
    },
    getModalPosition() {
      const offsetTop = 3.2; // Höhe des Markers in rem
      const offsetLeft = 3.2; // Breite des Markers in rem

      const newTop = `calc(${this.marker.position.top} + ${offsetTop}rem)`;
      const newLeft = `calc(${this.marker.position.left} + ${offsetLeft}rem)`;

      return {
        top: newTop,
        left: newLeft,
      };
    },
    handleClick(event) {
      event.stopPropagation();
      this.modalPosition = this.getModalPosition();
      this.isModalOpen = true;
    },
  },
}
</script>

<style lang="scss">
.map-marker {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  z-index: 150;
  opacity: 0;

  .decorator {
    margin-right: -20px;
    z-index: 125;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    border: 3px solid #fff;
    transition: border-color .6s, background-color .3s;
    flex-shrink: 0;

    .step-circle .step {
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  .svg-icon--plus {
    width: 1.5rem;
    height: 1.5rem;
    color: transparent !important;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 0;
    height: 3.45rem;
  }

  .label {
    margin-left: 20px;
    font-weight: 400;
    padding: 0 16px;
    font-size: 1.1rem;
    height: 100%;
    left: 0;
    max-width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 90%;
    }
  }

  &:hover {
    .content {
      min-width: calc(100% + 32px);
      width: 100%;

      .label {
        z-index: 150;
        max-width: 100%;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.data-flow {
    .decorator, .content {
      background-color: $primary-blue;

      .label {
        color: white;
      }
    }
  }

  &.goods-flow {
    color: $primary-blue;
    .decorator, .content {
      background-color: $primary-yellow;

      .label {
        color: $primary-blue;
      }
    }
  }

  &:hover {
    &.goods-flow {
      .decorator {
        background-color: lighten($primary-yellow, 10%);
        transition-duration: .2s;
      }
    }

    &.data-flow {
      .decorator {
        background-color: lighten($primary-blue, 10%);
        transition-duration: .2s;
      }
    }
  }
}

.pulse {
  width: 300px;
  height: 300px;
  background: rgb(24 41 131 / 50%);
  border: 1px solid rgba(128, 159, 255, 0.12);
  border-radius: 50%;
  pointer-events: none;
  position: absolute;
  transform: scale(0.1, 0.1);
  opacity: 0;
  animation: none;
  z-index: -1;
}

.pulse-active {
  animation: ring-1 3s ease-out 3, fadeOut 1s ease-in-out 9s forwards;
}

@keyframes ring-1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: .9;
    filter: blur(5px);
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
    filter: blur(5px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInAnimation {
  0%   { opacity:0; }
  100% { opacity:1; }
}

.fade-in {
  animation: fadeInAnimation .8s ease forwards;
}

@media (max-width: 1024px) {
  .map-marker {
    position: static;
    margin: 0.5rem 0;

    .content {
      display: inline-block;
      width: 100%;
    }

    .label {
      width: 100%;
      max-width: 100%;
      opacity: 1;
      visibility: visible;
      left: auto;
      transform: translateY(0);

      @media (max-width: 1024px) {
        //width: calc(90% - 32px);
      }
    }

    &:hover {
      .content {
        width: 100%;
        .label {
          width: 100%;
          max-width: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
</style>
