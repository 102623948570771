<template>
  <svg class="data-flow-lines" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 101;">
    <defs>
      <filter id="nebula" x="0" y="0" width="200%" height="200%">
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur"/>
        <feTurbulence type="fractalNoise" baseFrequency="0.02" numOctaves="5" result="turbulence">
          <animate attributeName="baseFrequency" dur="30s" values=".01;.02" repeatCount="indefinite"/>
        </feTurbulence>
        <feDisplacementMap in="blur" in2="turbulence" scale="10" xChannelSelector="R" yChannelSelector="G"/>
        <feComposite in="SourceGraphic" in2="displacementMap" operator="in" result="composite"/>
      </filter>
    </defs>

    <line
        v-for="(line, index) in dataFlowLines"
        :key="index"
        :class="{'animate-line': animateLines[index]}"
        :x1="line.start.x"
        :y1="line.start.y"
        :x2="line.end.x"
        :y2="line.end.y"
        :style="getLineAnimationStyle(index, calculateLineLength(line))"
        stroke="#ffffff"
        stroke-width="8"
        filter="url(#nebula)"
    />
  </svg>
</template>

<script>
export default {
  name: 'DataFlowLines',
  props: ['dataFlowLines', 'animateLines'],
  methods: {
    getLineAnimationStyle(index, length) {
      const delay = index * .3;
      return {
        strokeDasharray: `${length}`,
        strokeDashoffset: `${length}`,
        animation: `drawLine${index} .5s linear forwards ${delay}s`,
      };
    },
    calculateLineLength(line) {
      const dx = (line.end.x) - line.start.x;
      const dy = (line.end.y) - line.start.y;
      return Math.sqrt(dx * dx + dy * dy);
    },
  }
};
</script>

<style>
@keyframes drawLine {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
